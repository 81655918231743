var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "align-items": "center",
      "justify-content": "center",
      "ml": "15px"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "my": "10px",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-weight": "bold",
      "font-size": "18px",
      "color": "#111111"
    }
  }, [_vm._v(" Rencana makan ")]), _vm.isNutri ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "50px",
      "as": "router-link",
      "to": {
        name: 'nutri.meal-plan.edit'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "mr": "10px",
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "icon"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "flex": "1",
      "mt": "20px",
      "flex-dir": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('./img-meal-plan-empty.svg'),
      "alt": "meal plan empty illustration",
      "w": "200px"
    }
  }), _c('h1', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "10px",
      "color": "#111",
      "font-weight": "bold",
      "font-size": "16px",
      "font-family": "body"
    }
  }, [_vm._v(" Rencana makanmu masih kosong. ")]), _c('c-text', {
    attrs: {
      "mt": "10px",
      "max-w": "328px",
      "text-align": "center",
      "font-size": "14px",
      "color": "#828282"
    }
  }, [_vm._v(" Ahli gizi kami masih merencanakan rencana makan terbaik buat kamu. Mohon ditunggu, ya! ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }